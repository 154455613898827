<template>
  <div>

    <v-img
        src="../../../public/accounting.jpg"
        height="35vh"
        style="text-align: center"
    >
      <v-img
          src="../../../public/schulung_beratung_tile.png"
          class="d-inline-block top_tile"
          style="position: relative; top:50%; transform: translateY(-50%);"
          height="75%"
          contain
      ></v-img>
    </v-img>

    <div class="content_tile pa-5 pb-10 mt-4">
      <div class="row">
        <div class="col-md">
          <h2>Beratung</h2>
          <v-divider class="mb-5"></v-divider>
          <ul>
            <li>Einrichtung und Organisation von Buchhaltungs- und Belegwesen</li>
            <li>Buchhaltungs- und Belegwesen</li>
            <li>Organisation des Rechnungswesen</li>
            <li>Kennzahlensysteme</li>
            <li>Arbeits- und Sozialversicherungsrecht</li>
            <li>Förderungen</li>
            <li>Gründungsberatung</li>
            <li>In Abgabe- und Abgabestrafverfahren für Bundes-, Landes- und Gemeindeabgaben, in Beitragsangelegenheiten vor gesetzlich anerkannten Kirchen und Religionsgemeinschaften.</li>
            <li>Beim Arbeitsmarktservice, Berufsorganisationen</li>
            <li>Landesfremdenverkehrsverbände, und vor in Wirtschaftsangelegenheiten zutändigen Behörden und Ämtern, soweit diese mit den für den gleichen Auftraggeber durchzuführenden Tätigkeiten die mit SAISCHEK ACCOUNTING unmittelbar zusammenhängen.</li>
          </ul>
          <br>
          <p>SAISCHEK ACCOUNTING übernimmt keine Vertretung vor Gerichten, den Finanzbehörden, den unabhängigen Verwaltungssenaten und vor dem Verwaltungsgerichtshof.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Schulung",
  mounted() {
    const slide_down = {
      distance: '5%',
      origin: 'top',
      opacity: 0,
      delay: 1,
      duration: 1500,
      easing: 'ease-in-out'
    }

    this.$scroll.reveal(".top_tile", slide_down)
  }
}
</script>

<style scoped>

</style>
