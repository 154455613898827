<template>
  <v-container style="min-height: 55vh;" fluid class="background d-flex align-center">
    <transition name="slide-fade">
      <v-container v-if="show" class="container-lg content_tile bg pa-5 pb-10" style="height:100%;">

        <h1 style="text-transform: uppercase;">Wir garantieren</h1>

        <v-divider class="mb-5" style="border-color:#fff; border-width: 1px;"></v-divider>

        <v-row align="center">
          <v-col cols="2" class="grow-shrink-0">
            <v-img :src="img_src" max-height="250px" contain />
          </v-col>
          <v-col class="flex-grow-1">
            <ul>
              <li>Absolute Termintreue</li>
              <li>Verschwiegenheit</li>
              <li>Transparenz über Zeit- und Kostenaufwand durch statistische Belege</li>
              <li>Effiziente Bearbeitung Ihrer Unterlagen</li>
              <li>Abrechnung nach tats&auml;chlichem Aufwand</li>
            </ul>
            <p class="pt-5">
              Einen Blick über „den Tellerrand“ durch unser zus&auml;tzliches Angebot im Bereich Controlling.
            </p>
            <p>
              Sie profitieren von langj&auml;hriger Erfahrung und kaufen nur jene Dienstleistungen ein, die Sie tats&auml;chlich benötigen.
            </p>
            <p>
              Die Honorierung erfolgt nach tats&auml;chlichem Zeitaufwand in Form von Stundens&auml;tzen oder Pauschalhonorar.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </transition>
  </v-container>
</template>

<script>
export default {
  name: "WirGarantieren",
  data: () => ({
    img_src: require("../../public/wir_garantieren.jpg"),
    show: false,
  }),

  mounted() {
    this.show = true;
  }
}
</script>

<style scoped>
.bg {
  background-color: rgba(0, 97, 157, .85);
  color: #fff;
  /*box-shadow: inset 0 0 7px rgba(0,0,0,.35);*/
  /*border-radius: 3px;*/
  /*box-shadow: 0 1px 2px rgba(0,0,0,0.07),*/
  /*0 2px 4px rgba(0,0,0,0.07),*/
  /*0 4px 8px rgba(0,0,0,0.07),*/
  /*0 8px 16px rgba(0,0,0,0.07),*/
  /*0 16px 32px rgba(0,0,0,0.07),*/
  /*0 32px 64px rgba(0,0,0,0.07) !important;*/
  border-radius: 10px;
  /*background: #e0e0e0;*/
  box-shadow:  20px 20px 60px #919191,
  -20px -20px 60px #dcdcdc;
}

.content_tile {
  max-width:1000px !important;
}

.background {
  background-image: url("../../public/accounting.jpg");
  background-size: cover;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .8s ease-out;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  /*transition: all 1.1s ease-in-out;*/
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}

</style>
