import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const crypto = require('crypto')

export default new Vuex.Store({
  state: {
    logged_in: false,
    secret: "Saischek@123#"
  },
  mutations: {
    SET_LOGGED_IN(state, val) {
      state.logged_in = val;
    }
  },
  getters: {
    getLoggedIn(state) {
      return state.logged_in;
    },

    getHash: () => (str) => {
      return crypto.createHash('sha256').update(str).digest('hex');
    },
  },
  actions: {
  },
  modules: {
  }
})
