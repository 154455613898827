<template>
  <v-container fluid>
<!--    <v-progress-circular color="primary"  class="my-5" large indeterminate ></v-progress-circular>-->
    <v-img v-if="loading" :src="loader" width="64" class="my-15 mb-8" style="position: relative; left: 50%; transform: translateY(-50%);"></v-img>
    <v-row no-gutters>
      <v-col class="d-flex justify-center" v-for="item in news" :key="item.id">
        <NewsCard :item="item" :admin="admin" @delete="del" @edit="edit"></NewsCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewsCard from "@/components/NewsCard";
export default {
  name: "News",
  components: {NewsCard},
  data: () => ({
    admin: false,
    loader: require('../../public/loader.gif'),
    loading: true,
    news: []
  }),

  methods: {
    addItem(item, id) {
      item.id = id;

      if (!this.news) {
        this.news = [];
      }

      this.news.unshift(item);
    },

    init() {
      this.loading = true;
      this.axios.get('db.php')
          .then(res => {
            console.log(res)
            this.news = res.data;
            //this.news = res.data.sort((a, b) => {
            //  let a_ = new Date(a.timestamp);
            //  let b_ = new Date(b.timestamp);

            //  return b_ - a_;
            //});
            this.news.map(i => {
              let date = i.timestamp.split(" ")[0];
              let split = date.split("-");
              i.timestamp = split[2] + "." + split[1] + "." + split[0];
              //i.timestamp = new Date(i.timestamp).toLocaleDateString("de-DE", { day: "numeric", month: "short", year: "numeric"});
            });
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    edit(item) {
      this.$emit("edit", item);
    },

    del(id) {
      let it;
      for (it = 0; it < this.news.length; it++) {
        if (this.news[it].id === id) {
          this.news.splice(it, 1);
          break;
        }
      }
    }
  },

  mounted() {
    this.init();
    if (this.$route.name === "Admin") {
      this.admin = true;
    }
  }
}
</script>

<style scoped>
  .card {
    transition: all 0.2s ease-in-out;
  }

  .card:hover {
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0,0,0,0.07),
    0 2px 4px rgba(0,0,0,0.07),
    0 4px 8px rgba(0,0,0,0.07),
    0 8px 16px rgba(0,0,0,0.07),
    0 16px 32px rgba(0,0,0,0.07),
    0 32px 64px rgba(0,0,0,0.07) !important;
  }
</style>
