<template>
  <v-container fluid class="ma-0 pa-0 pb-8">

    <v-img
      src="../../public/shoes.png"
      height="50vh"
      max-height="50vh"
    />

    <v-container
        style="text-align: center; font-size: 1.3em; font-weight: 450; /*background-color: #ffffbb;*/"
        class="py-13"
        fluid
    >
      <span class="reveal_bottom">"Wenn Buchhalter Bücher halten, dann falten Zitronenfalter Zitronen."</span>
    </v-container>

    <v-divider></v-divider>

    <v-container style="max-width:800px; text-align: justify;" class="my-2">
      <h1 class="mb-2">Haben Sie Geld zu verschenken?</h1>
      <p>Oder einfach nur ein Chaos im System und keinerlei Überblick über die buchhalterischen Zahlen, Daten, Fakten Ihres Unternehmens?</p>
      <p>Brauchen Sie Unterstützung bei der Bewältigung Ihres betrieblichen Rechnungswesens, dann sind wir genau die richtigen Ansprechpartner für Sie.</p>
      <p>Wir bieten individuelle Pakete für unsere Kunden von der Lohnverrechnung bis zum Jahresabschluss und Controlling an.</p>
      <p>Was uns da so sicher macht?</p>
      <p>Fundiertes Fachwissen, umfangreiche Branchenkenntnisse, langjährige Erfahrung (in guten wie in schlechten Unternehmenszeiten), optimale Vernetzung und viel menschliches Feingefühl für die Bedürfnisse unserer KundInnen. Sie stehen bei uns im Mittelpunkt!
      Die Preise für unsere Leistungen sind fair und orientieren sich nach tatsächlich geleisteten Arbeitsstunden. Sie bezahlen nur das, was Sie auch wirklich benötigen.</p>
      <p><strong>Interessierten Sie sich für digitale Buchhaltung?</strong> – fragen Sie uns, wir sind seit mehreren Jahren Profis auf diesem Gebiet. Ob die Belege, für die Buchhaltung, über unser Klientenportal, per Mail oder vor Ort eingescannt werden – wird sind flexibel und sie auch – es besteht die Möglichkeit, dass Sie Ihre Belege jederzeit auch über Ihr Handy abrufen.</p>
    </v-container>

    <div id="splitter" class="container-lg">
      <div class="row fill-height">
        <div class="col-md" style="text-align:right;">
          <div>
            <p>Digitale Buchhaltung</p>
            <p>Jahresabschluss</p>
            <p>Personalverrechnung</p>
            <p>Controlling</p>
            <p>Beratung</p>
          </div>
        </div>
        <div class="col-md">
          <div>
            <p style="margin-bottom: 0 !important;">Klientenportal</p>
            <p style="font-size:0.6em; margin-top: 0 !important;">Jederzeit Zugriff auf ihre Dokumente</p>
            <p>Full-Service-Pakete</p>
            <p>Umstieg in die Digitalisierung</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" id="overview">
      <img class="reveal_bottom" id="overview_img" src="../../public/overview.png" alt="&Uumlbersicht"/>
    </div>

    <PuzzleGrid></PuzzleGrid>
</v-container>
</template>

<script>
import HelloWorld from '../components/HelloWorld'
import PuzzleGrid from "@/views/PuzzleGrid";

export default {
  name: 'Home',

  components: {
    HelloWorld,
    PuzzleGrid
  },

  data: () => ({
    window_width: window.innerWidth,
  }),

  methods: {
    onResize() {
      this.window_width = window.innerWidth
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  mounted() {

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    const slide_right = {
      distance: '50%',
      origin: 'left',
      opacity: 0,
      duration: 1250,
      easing: 'ease-in-out',
      mobile: false
    }

    const slide_left = {
      distance: '50%',
      origin: 'right',
      opacity: 0,
      duration: 1250,
      easing: 'ease-in-out',
      mobile: false
    }

    const slide_down = {
      distance: '5%',
      origin: 'top',
      opacity: .1,
      delay: 1,
      duration: 2000,
      easing: 'ease-in-out',
      mobile: false
    }

    if (!this.isMobile()) {
      this.$scroll.reveal(".reveal_left", slide_right)
      this.$scroll.reveal(".reveal_right", slide_left)
      this.$scroll.reveal(".reveal_bottom", slide_down)
      this.$scroll.reveal("#overview_img", slide_down)
    }
  }
}
</script>

<style>
/*#header_img {*/
/*  height: 65vh !important;*/
/*  background-position: center;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  box-shadow:inset 0 0 3rem rgba(0,0,0,.175) !important;*/
/*  background-image: url(../../public/Saischek_Schuhe.jpg);*/
/*  background-attachment: fixed;*/
/*}*/

#overview {
  padding: 2rem !important;
  height: 55vh !important;
  background-image: url(../../public/accounting.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
  box-shadow: inset 0 0rem 3rem rgba(0,0,0,.275) !important;
}

#overview_img {
  max-width: 1024px !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

#splitter {
  height: 55vh !important;
  background-color: #00619d;
  color: #fff;
  box-shadow: inset 0 0 7px rgba(0,0,0,.35);
}

#splitter .row {
  margin: 0;
  padding: 0;
}

#splitter .col-md {
  margin: 0;
  padding: 0;
}

#splitter p {
  margin: 0.8rem !important;
  display: block;
  text-transform: uppercase;
}

@media (max-width:768px) {
  #splitter .col-md {
    text-align: center !important;
  }

  #splitter .col-md:first-child {
    border-right: none !important;
    border-bottom: 2.5px solid #fff;
  }

  #splitter .col-md:nth-child(2) {
    border-left: none !important;
    border-top: 2.5px solid #fff;
  }

  #splitter .col-md div {
    font-size: 1.3rem;
  }
}

#splitter .col-md div {
  font-size: 1.3rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#splitter .col-md:first-child {
  border-right: 2.5px solid #fff;
}

#splitter .col-md:nth-child(2) {
  border-left: 2.5px solid #fff;
}

</style>
