import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Aktuelles from "@/views/Aktuelles";
import About from "@/views/About"
import WirGarantieren from "@/views/WirGarantieren";
import Links from "@/views/Links";
import NewsTools from "@/views/NewsTools";
import Impressum from "@/views/Impressum";

import Personalverrechnung from "@/views/leistungen/Personalverrechnung";
import Controlling from "@/views/leistungen/Controlling";
import JU_AV from "@/views/leistungen/JU_AV";
import Buchhaltung from "@/views/leistungen/Buchhaltung";
import Schulung from "@/views/leistungen/Schulung";

import Admin from "@/views/Admin"

Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/aktuelles',
    name: 'Aktuelles',
    component: Aktuelles
  },
  {
    path: '/wir_garantieren',
    name: 'Wir Garantieren',
    component: WirGarantieren
  },
  {
    path: '/links',
    name: 'Link',
    component: Links
  },
  {
    path: '/news_tools',
    name: 'NewsTools',
    component: NewsTools
  },
  {
    path: '/personalverrechnung',
    name: 'Personalverrechnung',
    component: Personalverrechnung
  },
  {
    path: '/controlling',
    name: 'Controlling',
    component: Controlling
  },
  {
    path: '/jahresabschluss',
    name: 'Jahresabschluss',
    component: JU_AV
  },
  {
    path: '/buchhaltung',
    name: 'Buchhaltung',
    component: Buchhaltung
  },
  {
    path: '/schulung_beratung',
    name: 'Schulung',
    component: Schulung
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
