<template>
  <div>

    <v-img
        src="../../../public/accounting.jpg"
        height="35vh"
        style="text-align: center"
    >
      <v-img
          src="../../../public/buchhaltung_tile.png"
          class="d-inline-block top_tile"
          style="position: relative; top:50%; transform: translateY(-50%);"
          height="75%"
          contain
      ></v-img>
    </v-img>

    <div class="content_tile pa-5 pb-10 mt-4">
      <div class="row">
        <div class="col-md">
          <p>
            Die Buchhaltung ist gesetzlich vorgeschrieben und bildet die Grundlage für eine effektive Kostenrechnung. Dadurch beeinflusst sie auch die künftigen Unternehmensentscheidungen und besteht nicht nur aus dem Sammeln von Rechnungen und Belegen und deren EDV-mäßiger Erfassung.
          </p>
          <p>Gerade für kleinere und mittlere Unternehmen ist es besonders empfehlenswert, die Buchhaltung auszulagern - durch SAISCHEK ACCOUNTING kann der Betrieb schlank gehalten, und eine kontinuierliche, exakte Überprüfung und Qualitätssicherung damit gesichert werden. </p>
          <p class="my-2 font-weight-bold">Tätigkeitsumfang:</p>
          <ul>
            <li>Doppelte Buchhaltung, auch Digital möglich mit Klientenportal</li>
            <li>Einnahmen/Ausgabenrechnung, auch Digital möglich mit Klientenportal</li>
            <li>Belegsortierung</li>
            <li>Belegbuchung</li>
            <li>Umsatzsteuervoranmeldung</li>
            <li>Monatliche Auswertungen<br />(Saldenliste, Offene Posten Liste, kurzfristige Erfolgsrechnung, Soll-/Istvergleich)</li>
            <li>Zahlungsverkehr</li>
            <li>Mahnwesen</li>
          </ul>
          <p class="mt-3">Schulung und Beratung im Buchhaltungs- und Belegwesen, sowie in der Organisation des Rechnungswesens</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Buchhaltung",
  mounted() {
    const slide_down = {
      distance: '5%',
      origin: 'top',
      opacity: 0,
      delay: 1,
      duration: 1500,
      easing: 'ease-in-out'
    }

    this.$scroll.reveal(".top_tile", slide_down)
  }
}
</script>

<style scoped>

</style>
