<template>
  <v-app>
    <v-app-bar
        id="nav"
        app
        height="128"
        color="white"
    >
      <v-img
          src="../public/logo.png"
          contain
          max-width="128"
      >

      </v-img>

      <v-spacer></v-spacer>

      <v-list color="transparent" v-show="!$vuetify.breakpoint.mdAndDown">
        <v-btn text to="/">
          <v-list-item-title>Home</v-list-item-title>
        </v-btn>
        <v-btn text to="about">
          <v-list-item-title>&Uuml;ber uns</v-list-item-title>
        </v-btn>
        <v-menu
            rounded
            offset-y
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
                text
                v-bind="attrs"
                v-on="on"
            >
              <v-list-item-title>Leistungen
                <v-icon>mdi-chevron-down</v-icon>
              </v-list-item-title>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
                link
                to="buchhaltung"
            >
              <v-list-item-title>Buchhaltung</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="controlling"
            >
              <v-list-item-title>Controlling</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="jahresabschluss"
            >
              <v-list-item-title>Jahresabschluss u. <br>Arbeitnehmerveranlagung</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="schulung_beratung"
            >
              <v-list-item-title>Schulung & Beratung</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="personalverrechnung"
            >
              <v-list-item-title>Personalverrechnung</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn text to="wir_garantieren">
          <v-list-item-title>Wir garantieren</v-list-item-title>
        </v-btn>
<!--        <v-btn text to="links">-->
<!--          <v-list-item-title>Links</v-list-item-title>-->
<!--        </v-btn>-->
        <v-btn text to="news_tools">
          <v-list-item-title>News & Tools</v-list-item-title>
        </v-btn>
      </v-list>

      <v-menu
        rounded
        offset-y
        min-width="250px"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-app-bar-nav-icon
              class="hidden-lg-and-up"
              v-bind="attrs"
              v-on="on"
          ></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item text to="/">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item text to="about">
            <v-list-item-title>&Uuml;ber uns</v-list-item-title>
          </v-list-item>
          <v-list-group
              @click.stop.prevent
          >
            <template v-slot:activator>
              <v-list-item-title>Leistungen</v-list-item-title>
            </template>
            <v-list-item
                link
                to="buchhaltung"
                class="pl-8"
            >
              <v-list-item-title>Buchhaltung</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="controlling"
                class="pl-8"
            >
              <v-list-item-title>Controlling</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="jahresabschluss"
                class="pl-8"
            >
              <v-list-item-title>Jahresabschluss u. <br>Arbeitnehmerveranlagung</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="schulung_beratung"
                class="pl-8"
            >
              <v-list-item-title>Schulung & Beratung</v-list-item-title>
            </v-list-item>
            <v-list-item
                link
                to="personalverrechnung"
                class="pl-8"
            >
              <v-list-item-title>Personalverrechnung</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item link to="wir_garantieren">
            <v-list-item-title>Wir Garantieren</v-list-item-title>
          </v-list-item>
<!--          <v-list-item link to="links">-->
<!--            <v-list-item-title>Links</v-list-item-title>-->
<!--          </v-list-item>-->
          <v-list-item link to="news_tools">
            <v-list-item-title>News & Tools</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
    <v-main>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <v-row class="pt-5 px-5 elevation-5" id="footer" justify="center">
      <v-col v-if="!$vuetify.breakpoint.smAndDown"></v-col>
      <v-col style="min-width: 190px !important;">
        <h5 class="text-uppercase">Saischek - Accounting</h5>
        <hr class="black mb-4 mt-0 d-inline-block" style="width: 60px;">
        <p>Buchhaltung... <br/>...und vieles mehr...</p>
        <v-btn color="primary" outlined @click="goto()">Kundenportal</v-btn>
      </v-col>
      <v-col style="min-width: 190px !important;">
        <h5 class="text-uppercase">Leistungen</h5>
        <hr class="black mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <router-link to="buchhaltung">Buchhaltung</router-link>
        </p>
        <p>
          <router-link to="controlling">Controlling</router-link>
        </p>
        <p>
          <router-link to="jahresabschluss">Jahresabschluss <br/> Arbeitnehmerveranlagung</router-link>
        </p>
        <p>
          <router-link to="schulung_beratung">Schulung & Beratung</router-link>
        </p>
        <p>
          <router-link to="personalverrechnung">Personalverrechnung</router-link>
        </p>
      </v-col>
      <v-col style="min-width: 136px !important;">
        <h5 class="text-uppercase">Links</h5>
        <hr class="black mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <router-link to="about">&Uuml;ber uns</router-link>
        </p>
        <p>
          <router-link to="wir_garantieren">Wir garantieren</router-link>
        </p>
<!--        <p>-->
<!--          <a href="links">Links</a>-->
<!--        </p>-->
        <p>
          <router-link to="news_tools">News & Tools</router-link>
        </p>
        <p>
          <router-link to="impressum">Impressum</router-link>
        </p>
      </v-col>
      <v-col style="min-width: 250px !important;">
        <h5 class="text-uppercase">Kontakt</h5>
        <hr class="black mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
<!--          <v-icon color="black" class="mr-2">mdi-home</v-icon>-->
          Andritzer Reichsstraße 42,<br/> 8045 Graz
        </p>
        <p>
<!--          <v-icon color="black" class="mr-2">mdi-mail</v-icon>-->
          office@saischek-accounting.at
        </p>
        <p>
<!--          <v-icon color="black" class="mr-2">mdi-phone</v-icon>-->
          0664 / 91 47 951
        </p>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.smAndDown"></v-col>
    </v-row>

    <notifications group="default" position="bottom right" />
  </v-app>

</template>

<style>
.vue-notification {
  box-shadow: 0 1px 1px rgba(0,0,0,0.12),
  0 2px 2px rgba(0,0,0,0.12),
  0 4px 4px rgba(0,0,0,0.12),
  0 8px 8px rgba(0,0,0,0.12),
  0 16px 16px rgba(0,0,0,0.12);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.userportal a {
  /* position: absolute;
  top: -13px;
  right: -10px; */
  margin-top: 2.5rem;
  border: 2px solid #0069D9;
  border-radius: 5px;
  padding: 5px 8px;
}

.userportal a {
  display: block;
  font-size: 0.9rem !important;
  text-align: center;
}

.userportal a:hover {
  background-color: #0069D9;
}

.userportal:hover > a {
  font-weight: normal;
  color: #fff;
}

#footer a {
  text-decoration: none;
}

#nav .v-btn {
  margin: 0 0.2rem;
}

.content_tile {
  max-width: 800px;
  margin: auto;
  text-align: justify;
}
</style>

<script>

export default {
  name: 'App',

  data: () => ({
    sidebar: false,
    menu: [
      {icon: 'home', title: 'Home', to: "/"},
      {icon: 'about', title: 'Über uns', to: "/about"},
      {icon: 'news', title: 'Aktuelles', to: "/aktuelles"},
      {icon: 'news', title: 'Leistungen', to: "/leistungen"},
      {icon: 'news', title: 'Leistungen', to: "/leistungen"},
    ]
  }),
  methods: {
    goto() {
      window.open("https://bmd.saischek-accounting.at", "_blank").focus()
    }
  }
};
</script>
