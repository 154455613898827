<template>
  <v-container class="cork shadow">
    <div class="row">
      <div class="col-md">
        <div class="card postit elevation-2 text-left mx-auto">
        <div class="card-body post-it-note">

          <div class="short_content card-text">
            <h1>Header</h1>
          </div>
          <h6 class="date">
          </h6>
          <div class="content" style="display:none;">

          </div>
        </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Aktuelles"
}
</script>

<style scoped>
.modal-body .header {
  font-size: 1.2em;
}

.col-md:nth-child(3n+2) .postit {
  transform: rotate(1deg);
}

.col-md:nth-child(2n) .postit {
  transform: rotate(-1.5deg);
}

.postit {
  background-color: rgb(255,215,7);
  float: left;
  width: 18rem;
  height: 18rem;
  margin: 1rem auto;
  cursor: pointer;
  border: 1px dashed rgba(255,215,7, 0.3);
  border-radius: 2px;
  padding: 0.5rem;
}

.postit .date
{
  color:rgba(0,0,0,.25);
}

.postit .header {
  margin-bottom: 0;
  font-weight: bold;
}

.tile_head {
  height: 50vh !important;
  margin: 0 auto !important;
  padding: 0 !important;
  background-image: url(../../public/accounting_2.png);
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  text-align: center;
}


.cork {
  margin:35px 15px;
  background-image: url(../../public/cork.png);
  border: 20px solid rgba(52,52,52,.85);
}

.overlay {
  height: 50vh !important;
  margin: 0 !important;
  background-image: url(../../public/aktuelles.png);
  background-position: center center;
  background-size: cover;
}

.tile_head h1 {
  height: 100%;
  line-height: 100%;
}

.content_tile {
  width: 65vw !important;
  margin: auto;
}

.short_content
{
  display:inline-block;
}
</style>
