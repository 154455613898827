<template>
  <v-card>
    <v-toolbar
        dark
        color="primary"
    >
      <v-dialog v-model="cancel_dialog" persistent width="unset">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            Wollen Sie wirklich abbrechen?
          </v-card-title>
          <v-card-text>Ihre eingegebenen Informationen gehen dadurch verloren</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="cancel_dialog = false"
            >
              Nein
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="cancel_dialog = false, close()"
            >
              Ja
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-toolbar-title>Online Anmeldung Dienstnehmer</v-toolbar-title>
    </v-toolbar>
    <v-stepper
        v-model="page"
        vertical
    >
      <v-stepper-step
          :complete="page > 1"
          step="1"
      >
        Firma
        <small>Angaben zur Firma</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card flat>
          <v-card-text>
            <v-form ref="company_form" v-model="company.company_valid" lazy-validation>
              <v-row>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="company.name">
                    <template #label>
                      Firmenname <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="company.street">
                    <template #label>
                      Straße <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field type="number" :rules="rules.required" v-model="company.plz">
                    <template #label>
                      PLZ <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="company.place">
                    <template #label>
                      Ort <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Telefon" v-model="company.tel"></v-text-field>
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="primary"
                @click="page = 1"
            >
              Zur&uuml;ck
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :disabled="!company.company_valid"
                @click="$refs.company_form.validate() ? page = 2 : null"
            >
              Weiter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
          :complete="page > 2"
          step="2"
      >
        Dienstnehmer/in
        <small>Angaben zum/zur Dienstnehmer/in</small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-form ref="employee_form" v-model="employee.employee_valid" lazy-validation>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field label="Titel" v-model="employee.title"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="employee.last_name">
                    <template #label>
                      Nachname <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="employee.first_name">
                    <template #label>
                      Vorname <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="employee.street">
                    <template #label>
                      Straße <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col style="max-width:200px;">
                  <v-text-field label="Hausnummer" v-model="employee.number">
                  </v-text-field>
                </v-col>
                <v-col style="max-width:200px;">
                  <v-text-field :rules="rules.required" type="number" v-model="employee.plz">
                    <template #label>
                      PLZ <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="employee.place">
                    <template #label>
                      Ort <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="employee.insurance_number">
                    <template #label>
                      Versicherungsnummer <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="employee.mail">
                    <template #label>
                      E-Mail <span class="red--text"></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="employee.phone" label="Telefon" style="width: 200px;"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Geschlecht<span class="red--text"><strong> *</strong></span></h2>
                  <v-radio-group v-model="employee.gender" :rules="rules.required">
                    <v-radio label="Männlich" value="Männlich"></v-radio>
                    <v-radio label="Weiblich" value="Weiblich"></v-radio>
                    <v-radio label="Divers" value="Divers"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <h2>Am Unternehmen beteiligt<span class="red--text"><strong> *</strong></span></h2>
                  <v-radio-group v-model="employee.participation" :rules="rules.required">
                    <v-radio label="Ja" value="Ja"></v-radio>
                    <v-radio label="Nein" value="Nein"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <h2>Mit Dienstgeber/in verwandt</h2>
                  <v-radio-group v-model="employee.relationship">
                    <v-radio label="Ja" value="Ja"></v-radio>
                    <v-radio label="Nein" value="Nein"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="employee.cash" label="Barauszahlung" @click="$refs.iban.validate()" true-value="Ja" false-value="Nein"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :disabled="employee.cash === 'Ja'" ref="iban" :rules="[ ibanRequired ]" v-model="employee.iban">
                    <template #label>
                      IBAN <span class="red--text" v-if="!employee.cash"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :disabled="employee.cash === 'Ja'" v-model="employee.bic">
                    <template #label>
                      BIC
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                      ref="unemployed_from"
                      v-model="employee.unemployed_from_modal"
                      :close-on-content-click="false"
                      :return-value.sync="employee.unemployed_from"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="employee.unemployed_from"
                          label="Arbeitslos von"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="employee.unemployed_from"
                        no-title
                        scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="employee.unemployed_from_modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.unemployed_from.save(employee.unemployed_from)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                      ref="unemployed_to"
                      v-model="employee.unemployed_to_modal"
                      :close-on-content-click="false"
                      :return-value.sync="employee.unemployed_to"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="employee.unemployed_to"
                          label="Arbeitslos bis"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="employee.unemployed_to"
                        no-title
                        scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="employee.unemployed_to_modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.unemployed_to.save(employee.unemployed_to)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Werbungskosten (nur bei Vorlage)</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="employee.single_earner" label="Alleinverdiener / Alleinerzieherabsetzbetrag" true-value="Ja" false-value="Nein"></v-checkbox>
                  <v-checkbox v-model="employee.exemption_notice" label="Freibetragsbescheid" true-value="Ja" false-value="Nein"></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox v-model="employee.commuter_bonus" label="Pendlerpauschale" true-value="Ja" false-value="Nein">
                    <template v-slot:label>
                      Pendlerpauschale
                      <small><a class="ml-3" target="_blank" href="https://pendlerrechner.bmf.gv.at/pendlerrechner/">(Pendlerrechner)</a></small>
                    </template>
                  </v-checkbox>

                  <v-checkbox v-model="employee.other" label="Sonstiges" true-value="Ja" false-value="Nein"></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 1"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :disabled="!employee.employee_valid"
                  @click="$refs.employee_form.validate() ? page = 3 : null"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>

      <!-- DIENSTVERHÄLTNIS -->

      <v-stepper-step
          :complete="page > 3"
          step="3"
      >
        Dienstverhätlnis
        <small>Angaben zum Dienstverhältnis</small>
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-form ref="employment_form" v-model="employment.employment_valid" lazy-validation>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field :rules="rules.required" v-model="employment.activity">
                    <template #label>
                      Bezeichnung der Tätigkeit <span class="red--text"><strong> *</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                      ref="employment_employed_since"
                      v-model="employment.employed_since_modal"
                      :close-on-content-click="false"
                      :return-value.sync="employment.employed_since"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="employment.employed_since"
                          label="Beschäftigt ab"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="employment.employed_since"
                        no-title
                        scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="employment.employed_since_modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.employment_employed_since.save(employment.employed_since)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-text-field label="Anrechenbare Berufsjahre" v-model="employment.work_years"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Befristung</h2>
                  <v-radio-group v-model="employment.time_limit">
                    <v-radio label="Ja" value="Ja"></v-radio>
                    <v-radio label="Nein" value="Nein"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <h2>Geringfügig beschäftigt <span class="red--text"><strong> *</strong></span></h2>
                  <v-radio-group v-model="employment.marginally_employed" :rules="rules.required">
                    <v-radio label="Ja" value="Ja"></v-radio>
                    <v-radio label="Nein" value="Nein"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Arbeitserlaubnis</h2>
                  <v-radio-group v-model="employment.work_permit">
                    <v-radio label="Ja" value="Ja"></v-radio>
                    <v-radio label="Nein" value="Nein"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <h2>Beschäftigt als<span class="red--text"><strong> *</strong></span></h2>
                  <v-radio-group v-model="employment.employed_as" :rules="rules.required">
                    <v-radio label="Arbeiter/in" value="Arbeiter/in"></v-radio>
                    <v-radio label="Angestellte/r" value="Angestellte/r"></v-radio>
                    <v-radio label="Freie/r Dienstnehmer/in" value="Freie/r Dienstnehmer/in"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="employment.salary_execution" label="Lohnexekution" true-value="Ja" false-value="Nein"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Beschäftigung pro Woche</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="MO" type="number" v-model="employment.hours.mo"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="DI" type="number" v-model="employment.hours.di"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="MI" type="number" v-model="employment.hours.mi"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="DO" type="number" v-model="employment.hours.do"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="FR" type="number" v-model="employment.hours.fr"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="SA" type="number" v-model="employment.hours.sa"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="SO" type="number" v-model="employment.hours.so"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 2"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :disabled="!employment.employment_valid"
                  @click="$refs.employment_form.validate() ? page = 4 : null"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>

      <v-stepper-step
          :complete="page > 4"
          step="4"
      >
        Einstufung lt. LV
        <small>Daten zur Einstufung lt. Kollektivvertrag</small>
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-form ref="collectiv_agreement_form" v-model="collective_agreement.collective_agreement_valid" lazy-validation>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field v-model="collective_agreement.collective_agreement" label="Kollektivvertrag"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="collective_agreement.employment_group" label="Beschäftigungsgruppe"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="collective_agreement.classification" label="Einstufung"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field v-model="collective_agreement.professional_year" label="Berufsjahr"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Buttogehalt vereinbart" type="number" suffix="€" v-model="collective_agreement.brutto_salary"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Überstundenpauschale" type="number" suffix="€" v-model="collective_agreement.overtime"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Srichtag für das nächste Berufsjahr</h2>
                </v-col>
              </v-row>
              <v-row>
              <v-col>
                <v-radio-group v-model="collective_agreement.cut_off_day">
                  <v-radio label="Eintrittsdatum" value="Eintrittsdatum"></v-radio>
                  <v-radio label="Jahresanfang" value="Jahresanfang"></v-radio>
                  <v-radio label="Tatsächlicher Stichtag" value="Tatsächlicher Stichtag"></v-radio>
                </v-radio-group>
              </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 3"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :diabled="!collective_agreement.collective_agreement_valid"
                  @click="$refs.collectiv_agreement_form.validate() ? page = 5 : null"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>

      <v-stepper-step
          :complete="page > 5"
          step="5"
      >
        Lehrlinge
        <small>Daten bei Lehrlingen</small>
      </v-stepper-step>

      <v-stepper-content step="5">
        <v-form>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-menu
                      ref="teaching_since"
                      v-model="trainees.teaching_since_modal"
                      :close-on-content-click="false"
                      :return-value.sync="trainees.teaching_since"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="trainees.teaching_since"
                          label="Vorlehre von"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="trainees.teaching_since"
                        no-title
                        scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="trainees.teaching_since_modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.teaching_since.save(trainees.teaching_since)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                      ref="teaching_until"
                      v-model="trainees.teaching_until_modal"
                      :close-on-content-click="false"
                      :return-value.sync="trainees.teaching_until"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="trainees.teaching_until"
                          label="Vorlehre bis"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="trainees.teaching_until"
                        no-title
                        scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="trainees.teaching_until_modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.teaching_until.save(trainees.teaching_until)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 4"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="page = 6"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>


      <v-stepper-step
          :complete="page > 6"
          step="6"
      >
        Sonstiges
      </v-stepper-step>

      <v-stepper-content step="6">
        <v-form>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-textarea v-model="other.notes" outlined label="Anmerkungen">
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <h2>Dokumente</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                      v-model="other.files"
                      color="primary"
                      multiple
                      placeholder="Klicken um Dateien hinzuzufügen"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                          v-if="index < 4"
                          color="primary accent-4"
                          dark
                          label
                          small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                          v-else-if="index === 4"
                          class="text-overline grey--text text--darken-3 mx-2"
                      >
                          +{{ other.files.length - 2 }} Dokument(e)
                        </span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 5"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="page = 7"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>


      <v-stepper-step
          :complete="page > 7"
          step="7"
      >
        Versand
      </v-stepper-step>

      <v-stepper-content step="7">
        <v-form ref="dispatch_form" v-model="dispatch.valid" lazy-validation>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-checkbox v-model="dispatch.copy" label="Eine Kopie an meine E-Mail-Adresse senden" @click="$refs.mail.validate()" true-value="Ja" false-value="Nein"></v-checkbox>
                </v-col>
                <v-col>
                  <v-text-field v-model="dispatch.mail" label="Ihre E-Mail-Adresse" ref="mail" :disabled="dispatch.copy === 'Nein'" :rules="[ mailRequired ]" ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox :rules="rules.required" v-model="dispatch.agreement">
                    <template #label>
                      <p><span class="red--text"><strong> *</strong></span> Ich versichere, die oben gemachten Angaben vollständig und wahrheitsgemäß gemacht zu haben.
                      Sollten sich im Verlauf meiner Beschäftigung Veränderungen ergeben, werde ich diese unverzüglich
                      mitteilen. Soweit meinem Arbeitgeber durch unvollständige oder unrichtige Angaben Nachteile
                      entstehen, bin ich schadenersatzpflichtig</p>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 6"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :disabled="!dispatch.valid"
                  :loading="loading"
                  @click="$refs.dispatch_form.validate() ? send() : null"
              >
                Absenden
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-stepper-content>
    </v-stepper>
  </v-card>
</template>

<script>
import { serialize } from 'object-to-formdata';

export default {
  name: "Anmeldung",
  data: () => ({
    page: 1,
    rules: {
      required: [v => !!v || "Erforderlich"],
      iban_required: [v => !!v && !this.employee.cash || "Erforderlich"],
    },
    cancel_dialog: false,
    // 1. company form
    company: {
      company_valid: true,
      street:null,
      name: null,
      plz: null,
      place: null,
      tel: null,
    },
    // 2. employee form
    employee: {
      employee_valid: true,
      title: null,
      last_name: null,
      first_name: null,
      street: null,
      number: null,
      plz: null,
      place: null,
      insurance_number: null,
      mail: null,
      phone: null,
      gender: null,
      relationship: null,
      participation: null,
      cash: "Nein",
      iban: null,
      bic: null,
      unemployed_from_modal: false,
      unemployed_from: null,
      unemployed_to_modal: false,
      unemployed_to: null,
      single_earner: null,
      exemption_notice: null,
      commuter_bonus: null,
      other: null
    },
    // 3. Employment form
    employment: {
      employment_valid: true,
      activity: null,
      employed_since: null,
      employed_since_modal: false,
      work_years: null,
      time_limit: null,
      marginally_employed: null,
      work_permit: null,
      employed_as: null,
      salary_execution: null,
      hours: {
        mo: null,
        di: null,
        mi: null,
        do: null,
        fr: null,
        sa: null,
        so: null
      }
    },
    // 4. Collective Agreement from
    collective_agreement: {
      collective_agreement_valid: true,
      collective_agreement: null,
      enployment_group: null,
      classification: null,
      professional_year: null,
      cut_off_day: null,
      brutto_salary: null,
      overtime: null
    },
    // 5. Trainees form
    trainees: {
      teaching_since: null,
      teaching_since_modal: false,
      teaching_until: null,
      teaching_until_modal: false,
    },
    // 6. Other form
    other: {
      notes: null,
      files: [],
    },
    // 7. Dispatch form
    dispatch: {
      valid: true,
      mail: null,
      copy: "Nein",
      agreement: null,
    },
    options: {
      /**
       * include array indices in FormData keys
       * defaults to false
       */
      indices: false,

      /**
       * treat null values like undefined values and ignore them
       * defaults to false
       */
      nullsAsUndefineds: false,

      /**
       * convert true or false to 1 or 0 respectively
       * defaults to false
       */
      booleansAsIntegers: false,

      /**
       * store arrays even if they're empty
       * defaults to false
       */
      allowEmptyArrays: false,

      /**
       * don't include array notation in FormData keys for Files in arrays
       * defaults to false
       */
      noFilesWithArrayNotation: false,

      /**
       * use dots instead of brackets for object notation in FormData keys
       * defaults to false
       */
      dotsForObjectNotation: true,
    },
    loading: false,
  }),
  methods: {
    close() {
      this.$emit("close");
    },

    ibanRequired(val) {
      if (!val && this.employee.cash === "Nein") {
        return "Required";
      } else {
        return true;
      }
    },

    mailRequired(val) {
      if (!val && this.dispatch.copy === "Ja") {
        return "Required";
      } else {
        return true;
      }
    },

    send() {
      this.loading = true;

      const data = {
        company: this.company,
        employee: this.employee,
        employment: this.employment,
        collective_agreement: this.collective_agreement,
        trainees: this.trainees,
        other: this.other,
        dispatch: this.dispatch
      };

      let fd = new FormData();

      let it;
      for (it = 0; it < this.other.files.length; it++) {
        fd.append("userfile_" + it, this.other.files[it]);
      }

      fd.append("total_files", this.other.files.length);
      fd.append("event", "registration");

      fd = serialize(data, this.options, fd);

      this.axios
          // .post("/mail.php", fd)
          .post("http://www.saischek-accounting.at/mail.php", fd)
          .then(res => {
            if (res.data == true) {
              this.$notify({
                group: 'default',
                title: 'Erfolgreich',
                type: 'success',
                text: 'Ihre Dienstnehmer Anmeldung wurde erfolgreich versand!'
              });
              this.$emit("close");
            } else {
              this.$notify({
                group: 'default',
                title: 'Fehler',
                type: 'error',
                text: 'Ein Fehler ist aufgetreten! Bitte überprüfen Sie ihre eingegebenen Daten!'
              });
              console.error(res.data);
            }
          })
          .catch(err => {
            this.$notify({
              group: 'default',
              title: 'Fehler',
              type: 'error',
              text: 'Ein Fehler ist aufgetreten! Bitte versuchen Sie es erneut!'
            });
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
