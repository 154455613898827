<template>
<v-container id="puzzle_grid">
  <v-row>
    <v-col>
      <v-card flat class="reveal_left">
        <router-link to="buchhaltung">
          <v-img src="../../public/tiles/buchhaltung.png"></v-img>
        </router-link>
        <v-card-text>
          <ul>
            <li>Digitale & analoge Buchhaltung</li>
            <li>Mobile Datenabfrage</li>
            <li>Mahnwesen</li>
            <li>Auswertungen</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card flat class="reveal_right">
        <router-link to="personalverrechnung">
          <v-img src="../../public/tiles/personalverrechnung.png"></v-img>
        </router-link>
        <v-card-text>
          <ul>
            <li>Digitale Lohnverrechnung</li>
            <li>Gehaltszettelversand per Mail</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row class="hidden-sm-and-down">
    <v-col>
      <v-divider></v-divider>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card flat class="reveal_left">
        <router-link to="jahresabschluss">
          <v-img src="../../public/tiles/jahresabschluss.png"></v-img>
        </router-link>
        <v-card-text>
          <ul>
            <li>Anlagenverzeichnis</li>
            <li>Bilanz, Gewinn- und Verlustrechnung</li>
            <li>Abschluss von Büchern (Überschussrechnung)</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>
      <v-card flat class="reveal_right">
        <router-link to="controlling">
          <v-img src="../../public/tiles/controlling.png"></v-img>
        </router-link>
        <v-card-text>
          <ul>
            <li>Budgetierung</li>
            <li>Finanzplan</li>
            <li>Cashflow/Kapitalverlustrechnung</li>
            <li>Soll/Ist-Vergleich</li>
            <li>Report</li>
            <li>Kostenrechnung</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row class="hidden-sm-and-down">
    <v-col>
      <v-divider></v-divider>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-card flat class="reveal_bottom">
        <router-link to="schulung_beratung">
          <v-img src="../../public/tiles/schulung_beratung.png"></v-img>
        </router-link>
        <v-card-text>
          <ul>
            <li>Unterstützung beim Umstieg in die Digitalisierung des Rechnungswesens</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
name: "Test",

  mounted() {
    const slide_right = {
      distance: '25%',
      origin: 'left',
      opacity: .1,
      duration: 1250,
      easing: 'ease-in-out'
    }

    const slide_left = {
      distance: '25%',
      origin: 'right',
      opacity: .1,
      duration: 1250,
      easing: 'ease-in-out'
    }

    const slide_down = {
      distance: '25%',
      origin: 'top',
      opacity: .1,
      delay: 1,
      duration: 2000,
      easing: 'ease-in-out'
    }

    this.$scroll.reveal(".reveal_left", slide_right)
    this.$scroll.reveal(".reveal_right", slide_left)
    this.$scroll.reveal(".reveal_bottom", slide_down)
  }
}
</script>

<style scoped>
#puzzle_grid {
  max-width: 1000px;
}

#puzzle_grid .col {
  display: flex;
  justify-content: center;
}

#puzzle_grid .v-card {
  max-width: 400px;
}

#puzzle_grid .v-image {
  margin: 0 auto;
  max-width: 300px;
  transition: all 0.2s ease-in-out;
}

#puzzle_grid .v-image:hover {
  transform: scale(1.06);
}
#puzzle_grid ul li {
  color: #000000;
  font-size: 1.15em;
}

</style>
