<template>
  <v-container>
<!--    <h1>Tools</h1>-->
    <v-container class="pb-5">
      <v-row>
        <v-col class="d-flex justify-end">
          <!-- FULLSCREEN -->
          <span class="hidden-md-and-up">
            <v-dialog
                class="hidden-md-and-down"
                persistent
                fullscreen
                v-model="register_modal_fullscreen"
                width="1000px"
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card align="center"
                        max-width="350px"
                        flat
                        shaped
                        v-bind="attrs"
                        v-on="on"
                        class="hover">
                  <v-icon size="64">mdi-book-open-page-variant</v-icon>
                  <v-card-text>
                    <h2 class="mb-2">Online Anmeldung Dienstnehmer</h2>
                    <p>Dienstnehmerdaten für Anmeldung online erfassen</p>
                  </v-card-text>
                </v-card>
              </template>

              <Anmeldung @close="register_modal_fullscreen = false;"></Anmeldung>
            </v-dialog>
          </span>
          <!-- MOBILE -->
          <span class="hidden-sm-and-down">
            <v-dialog
                class="hidden-md-and-down"
                persistent
                v-model="register_modal"
                width="1000px"
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card align="center" max-width="350px" flat shaped           v-bind="attrs"
                        v-on="on" class="hover">
                  <v-icon size="64">mdi-book-open-page-variant</v-icon>
                  <v-card-text>
                    <h2 class="mb-2">Online Anmeldung Dienstnehmer</h2>
                    <p>Dienstnehmerdaten für Anmeldung online erfassen</p>
                  </v-card-text>
                </v-card>
              </template>

              <Anmeldung @close="register_modal = false;"></Anmeldung>
            </v-dialog>
          </span>
        </v-col>
        <v-col class="d-flex justify-start">
          <!-- FULLSCREEN -->
          <span class="hidden-md-and-up">
            <v-dialog
                fullscreen
                persistent
                width="1000px"
                v-model="deregister_modal_fullscreen"
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card align="center" max-width="350px" flat shaped            v-bind="attrs"
                        v-on="on" class="hover">
                  <v-icon size="64">mdi-book-open-page-variant</v-icon>
                  <v-card-text>
                    <h2 class="mb-2">Online Abmeldung Dienstnehmer</h2>
                    <p>Dienstnehmerdaten für Abmeldung online erfassen</p>
                  </v-card-text>
                </v-card>
              </template>

              <Abmeldung @close="deregister_modal_fullscreen = false;"></Abmeldung>
            </v-dialog>
          </span>
          <!-- MOBILE -->
          <span class="hidden-sm-and-down">
            <v-dialog
                persistent
                width="1000px"
                v-model="deregister_modal"
                transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card align="center" max-width="350px" flat shaped            v-bind="attrs"
                        v-on="on" class="hover">
                  <v-icon size="64">mdi-book-open-page-variant</v-icon>
                  <v-card-text>
                    <h2 class="mb-2">Online Abmeldung Dienstnehmer</h2>
                    <p>Dienstnehmerdaten für Abmeldung online erfassen</p>
                  </v-card-text>
                </v-card>
              </template>

              <Abmeldung @close="deregister_modal = false;"></Abmeldung>
            </v-dialog>
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="mb-3"></v-divider>
    <News></News>
  </v-container>
</template>

<script>
import Anmeldung from "@/views/Anmeldung";
import Abmeldung from "@/views/Abmeldung";
import News from "@/components/News";

export default {
  name: "NewsTools",

  components: {News, Anmeldung, Abmeldung },

  data: () => ({
    register_modal: false,
    register_modal_fullscreen: false,
    deregister_modal: false,
    deregister_modal_fullscreen: false,
  }),

  methods: {
    send() {
      this.axios.post("mail.php")
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
    },
  }
}
</script>

<style scoped>
.content_tile {
  max-width: 1200px;
  margin: auto;
}

.hover {
  transition: all 0.1s ease-in-out;
}

.hover:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.v-card i {
  color: #525252 !important;
}

.v-card__text {
  color: #000000 !important;
}
</style>
