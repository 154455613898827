<template>
  <v-card width="300" flat>
    <v-card-text>
      <v-text-field type="password" v-model="password" label="Passwort" v-on:keyup.enter="login()"></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="login()">Login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  data: () => ({
    password: null
  }),
  methods: {
    login() {
      if (this.password === this.secret) {
        this.$notify({
          group: 'default',
          title: 'Erfoglreich',
          type: 'success',
          text: 'Erfolgreich angemeldet!'
        });

        this.$store.commit("SET_LOGGED_IN", true);
        this.$cookies.set("login", this.getHash(this.secret));
      } else {
        this.$notify({
          group: 'default',
          title: 'Fehler',
          type: 'error',
          text: 'Ihr eingegebenes Passwort ist falsch!'
        });

        this.$store.commit("SET_LOGGED_IN", false);
      }
    }
  },
  created() {
    const hash = this.$cookies.get("login");
    if (hash && (hash === this.getHash(this.secret))) {
      this.$store.commit("SET_LOGGED_IN", true);
    } else {
      this.$store.commit("SET_LOGGED_IN", false);
    }
  },
  computed: {
    ...mapState(["secret"]),
    ...mapGetters(["getHash"]),
  }
}
</script>

<style scoped>
  .v-card  {
    box-shadow: 0 1px 2px rgba(0,0,0,0.07),
    0 2px 4px rgba(0,0,0,0.07),
    0 4px 8px rgba(0,0,0,0.07),
    0 8px 16px rgba(0,0,0,0.07),
    0 16px 32px rgba(0,0,0,0.07),
    0 32px 64px rgba(0,0,0,0.07) !important;
  }
</style>
