<template>
  <div>

    <v-img
        src="../../../public/accounting.jpg"
        height="35vh"
        style="text-align: center"
    >
      <v-img
          src="../../../public/jahresabschluss_tile.png"
          class="d-inline-block top_tile"
          style="position: relative; top:50%; transform: translateY(-50%);"
          height="75%"
          contain
      ></v-img>
    </v-img>

    <div class="content_tile pa-5 pb-10 mt-4">
      <div class="row">
        <div class="col-md">
          <h1>Jahresabschluss (bis zu gewissen Umsatzgrenzen)</h1>
          <v-divider class="mb-3"></v-divider>
          <p>Am Ende zählt immer das, was unter dem Strich bleibt - ob es sich nun um einen 3-Mann-Betrieb oder eine Kapitalgesellschaft mit hunderten Mitarbeitern handelt. Deswegen kann man Bilanzen und Jahresabschlüsse nicht als reine Pflichterfüllung den Behörden gegenüber sehen. Vielmehr sind sie ein enorm wichtiges Instrument zur Zukunftsplanung.</p>
          <p>Bilanzerstellung ist für SAISCHEK ACCOUNTING Routine, ich bringe die Zahlen in eine verständliche Form und kann dadurch mit dem Unternehmer gemeinsam zukünftige Strategien erarbeiten.</p>

          <h1>Tätigkeitsumfang:</h1>
          <v-divider class="mb-3"></v-divider>
          <ul>
            <li>Erstellung der Bilanz (EStG § 4 Abs 1, EStG § 5 Abs 1) bis zu gewissen Umsatzgrenzen</li>
            <li>Erstellung der Einnahmen- und Ausgabenrechnung (EStG § 4 Abs 3)</li>
          </ul>
          <br />

          <h1 style="display: inline-block;" class="mr-3">Arbeitnehmerveranlagung</h1>
          <span class="subtitle-2" style="font-size: 1.2em !important;">(Geld zurück vom Finanzamt!)</span>
          <v-divider class="mb-3"></v-divider>
          <p>Wir unterstützen Sie auch gerne bei der Erstellung Ihrer Arbeitnehmerveranlagung und holen dabei so viel wie möglich von ihrer bezahlten Steueraufwendung zurück.</p>
          <p>Wir beraten Sie gerne in Bezug auf Werbungskosten, Sonderaufwendungen und andere Dinge, die sie von Ihrer Lohnsteuer absetzen könnten.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JU_AV",
  mounted() {
    const slide_down = {
      distance: '5%',
      origin: 'top',
      opacity: 0,
      delay: 1,
      duration: 1500,
      easing: 'ease-in-out'
    }

    this.$scroll.reveal(".top_tile", slide_down)
  }
}
</script>

<style scoped>

</style>
