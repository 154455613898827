import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ScrollReveal from "scrollreveal";
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import vue_cookies from 'vue-cookies'

// const ENV = "dev";
const ENV =  process.env.NODE_ENV;
// const ENV = "prod";

if (ENV === "development") {
  axios.defaults.baseURL = "http://localhost:80/public";
} else if (ENV === "production") {
  axios.defaults.baseURL = "/";
}

axios.defaults.maxBodyLength = Infinity;

Vue.use(VueAxios, axios)
Vue.use(Notifications)
Vue.use(vue_cookies)

Vue.config.productionTip = false
Vue.prototype.$scroll = ScrollReveal();

document.title = "Saischek - Accounting"

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
