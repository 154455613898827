<template>
  <div>
    <v-img
        src="../../../public/accounting.jpg"
        height="35vh"
        style="text-align: center"
    >
      <v-img
          src="../../../public/controlling_tile.png"
          class="d-inline-block top_tile"
          style="position: relative; top:50%; transform: translateY(-50%);"
          height="75%"
          contain
      ></v-img>
    </v-img>

    <div class="content_tile pa-5 pb-10 mt-4">
      <div class="row">
        <div class="col-md">
          <p>Wenn die Gewinne ausbleiben, dann ist guter Rat teuer. Wenn die Kosten explodieren und die Erlöse stagnieren, wie geht man damit um?</p>

          <p>Spätestens dann können wir – <b>SAISCHEK ACCOUNTING</b> – Ihnen helfen. Wir analysieren auf Basis von</p>
          <ul>
            <li>Geeigneten Kostenrechnungssystemen</li>
            <li>Deckungsbeitragsrechung</li>
            <li>Plankostenrechnung</li>
            <li>Budgetierung</li>
            <li>Finanzplanung</li>
            <li>Cashflow/Kapitalflussrechnung</li>
            <li>Liquiditätsplanung</li>
            <li>Soll/Ist-Vergleich</li>
            <li>Reporting</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Controlling",
  mounted() {
    const slide_down = {
      distance: '5%',
      origin: 'top',
      opacity: 0,
      delay: 1,
      duration: 1500,
      easing: 'ease-in-out'
    }

    this.$scroll.reveal(".top_tile", slide_down)
  }
}
</script>

<style scoped>

</style>
