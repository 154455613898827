<template>
  <v-container class="fill-height">
    <v-container v-if="!logged_in" class="d-flex justify-center">
      <Login></Login>
    </v-container>
    <v-container>
      <v-btn color="primary" outlined @click="logout()" class="float-end">Abmelden</v-btn>
    </v-container>
    <v-container v-if="logged_in" id="new_container">
      <v-row align="center" justify="center">
        <v-col class="hidden-md-and-down"></v-col>
        <v-col align="center">
          <v-img style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;" :src="preview" width="380px"
                 height="320px" class="mb-5"></v-img>
          <v-btn
              color="primary"
              rounded
              dark
              @click="uploadImage"
              type="file"
          >
            Bild hochladen
            <v-icon class="ml-3">mdi-upload</v-icon>
          </v-btn>
          <v-btn :disabled="!edited_item.image" color="primary" class="ml-3" rounded
                 @click="edited_item.image = '', preview = preview_default">
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <!-- Create a File Input that will be hidden but triggered with JavaScript -->
          <input
              ref="uploader"
              class="d-none"
              type="file"
              @change="onImageChange"
          ></v-col>
        <v-col class="hidden-md-and-down"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-md-and-down"></v-col>
        <v-col>
          <v-text-field v-model="edited_item.title" label="Titel" class="mt-5"></v-text-field>
        </v-col>
        <v-col class="hidden-md-and-down"></v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="hidden-md-and-down"></v-col>
        <v-col cols="8">
          <quill-editor
              ref="myQuillEditor"
              class="editor"
              :options="{ placeholder: 'Text hier eingeben...' }"
              v-model="edited_item.text"
          />
        </v-col>
        <v-col class="hidden-md-and-down"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-md-and-down"></v-col>
        <v-col cols="8">
          <span v-if="edited_item.image || edited_item.text || edited_item.title">
            <h3 class="mt-5">Vorschau:</h3>
            <v-divider class="mb-3"></v-divider>

            <NewsCard :item="edited_item"></NewsCard>
          </span>
        </v-col>
        <v-col class="hidden-md-and-down"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-md-and-down"></v-col>
        <v-col align="end">
          <v-btn color="primary" class="mb-5" :disabled="!edited_item.title || !edited_item.text" @click="save()"
                 :loading="loading">Speichern
          </v-btn>
          <v-divider v-if="logged_in"></v-divider>
        </v-col>
        <v-col class="hidden-md-and-down"></v-col>
      </v-row>

    </v-container>
    <News v-if="logged_in" ref="news" @edit="edit"></News>
  </v-container>
</template>

<script>
import News from "@/components/News";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'

import Login from "@/components/Login";
import {mapState} from "vuex";

import NewsCard from "@/components/NewsCard";

export default {
  name: "Admin",
  components: {Login, News, quillEditor, NewsCard},
  data: () => ({
    loading: false,
    preview: require("../../public/placeholder.png"),
    preview_default: require("../../public/placeholder.png"),
    dialog: false,
    edited_item: {
      title: "",
      text: "",
      image: null
    },
    default_item: {
      title: null,
      text: null,
      image: null
    }
  }),
  methods: {
    edit(item) {
      this.edited_item.text = item.text;
      this.edited_item.title = item.title;
    },

    async save() {
      this.loading = true;

      const img = this.$refs.uploader.files[0] ? this.$refs.uploader.files[0] : null;

      this.edited_item.text = this.edited_item.text.replaceAll("<p>&nbsp;</p>", "")
      this.edited_item.text = this.edited_item.text.replaceAll("<p><br></p>", "<br>")
      // this.edited_item.text = this.edited_item.text.replaceAll("</p><p><br></p><p>", "</p><p>")

      const parser = new DOMParser();
      const doc = parser.parseFromString(this.edited_item.text, "text/html");
      const imgElement = doc.querySelector("img");

      if (imgElement) {
        imgElement.setAttribute("style", "max-width: 100%;");
        this.edited_item.text = doc.body.innerHTML;
      }

      let fd = new FormData();
      fd.append('title', this.edited_item.title);
      fd.append('text', this.edited_item.text);
      fd.append('image', img);
      fd.append('timestamp', new Date().toISOString().slice(0, 19).replace('T', ' '));

      await this.axios
          .post("db.php", fd)
          .then(res => {
            if (res.data && res.data > 0) {
              this.$refs.news.addItem(Object.assign({}, this.edited_item), res.data);
              this.clear();
              this.$notify({
                group: 'default',
                title: 'Erfolgreich!',
                type: 'success',
                text: 'Ihr Eintrag wurde erfolgreich erstellt!'
              });
            } else if (parseInt(res.data) === -1) {
              this.$notify({
                group: 'default',
                title: 'Bild Fehler!',
                type: 'error',
                text: 'Ihr gewähltes Bild konnte nicht hochgeladen werden!'
              });
            } else if (parseInt(res.data) === -2) {
              this.$notify({
                group: 'default',
                title: 'Bildformat Fehler!',
                type: 'error',
                text: 'Unterstützte Bildformate: jpg, jpeg, png, gif'
              });
            } else {
              this.$notify({
                group: 'default',
                title: 'Fehler!',
                type: 'error',
                text: 'Ein allgemeiner Fehler ist aufgetreten! Ihr Eintrag konnte nicht erstellt werden!'
              });
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
          })
    },

    clear() {
      this.edited_item = this.default_item;
      this.preview = this.preview_default;
    },

    openDialog() {
      this.dialog = true;
    },

    uploadImage() {
      this.$refs.uploader.click();
    },

    onImageChange(e) {
      const file = e.target.files[0];

      if (!file || file.length < 1) {
        return;
      }

      if (file instanceof Blob) {
        // blob bugfix: sometimes v-input provides no blob
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", e => {
          this.edited_item.image = e.target.result;
          this.preview = e.target.result;
        });
      }
    },

    logout() {
      this.$cookies.remove("login");
      location.reload()
    }
  },

  watch: {
    edited_item: {
      handler(val) {
        if (val.text) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(val.text, "text/html");
          const imgElement = doc.querySelector("img");

          if (imgElement) {
            imgElement.setAttribute("style", "max-width: 100%;");
            val.text = doc.body.innerHTML;
          }
        }
      },
      deep: true,
    }
  },

  computed: {
    ...mapState(["logged_in"])
  }
}
</script>

<style scoped>
.preview {
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
}

.preview:hover {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07),
  0 2px 4px rgba(0, 0, 0, 0.07),
  0 4px 8px rgba(0, 0, 0, 0.07),
  0 8px 16px rgba(0, 0, 0, 0.07),
  0 16px 32px rgba(0, 0, 0, 0.07),
  0 32px 64px rgba(0, 0, 0, 0.07) !important;
}
</style>
