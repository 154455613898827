var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-container',{staticClass:"pb-5"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('span',{staticClass:"hidden-md-and-up"},[_c('v-dialog',{staticClass:"hidden-md-and-down",attrs:{"persistent":"","fullscreen":"","width":"1000px","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"hover",attrs:{"align":"center","max-width":"350px","flat":"","shaped":""}},'v-card',attrs,false),on),[_c('v-icon',{attrs:{"size":"64"}},[_vm._v("mdi-book-open-page-variant")]),_c('v-card-text',[_c('h2',{staticClass:"mb-2"},[_vm._v("Online Anmeldung Dienstnehmer")]),_c('p',[_vm._v("Dienstnehmerdaten für Anmeldung online erfassen")])])],1)]}}]),model:{value:(_vm.register_modal_fullscreen),callback:function ($$v) {_vm.register_modal_fullscreen=$$v},expression:"register_modal_fullscreen"}},[_c('Anmeldung',{on:{"close":function($event){_vm.register_modal_fullscreen = false;}}})],1)],1),_c('span',{staticClass:"hidden-sm-and-down"},[_c('v-dialog',{staticClass:"hidden-md-and-down",attrs:{"persistent":"","width":"1000px","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"hover",attrs:{"align":"center","max-width":"350px","flat":"","shaped":""}},'v-card',attrs,false),on),[_c('v-icon',{attrs:{"size":"64"}},[_vm._v("mdi-book-open-page-variant")]),_c('v-card-text',[_c('h2',{staticClass:"mb-2"},[_vm._v("Online Anmeldung Dienstnehmer")]),_c('p',[_vm._v("Dienstnehmerdaten für Anmeldung online erfassen")])])],1)]}}]),model:{value:(_vm.register_modal),callback:function ($$v) {_vm.register_modal=$$v},expression:"register_modal"}},[_c('Anmeldung',{on:{"close":function($event){_vm.register_modal = false;}}})],1)],1)]),_c('v-col',{staticClass:"d-flex justify-start"},[_c('span',{staticClass:"hidden-md-and-up"},[_c('v-dialog',{attrs:{"fullscreen":"","persistent":"","width":"1000px","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"hover",attrs:{"align":"center","max-width":"350px","flat":"","shaped":""}},'v-card',attrs,false),on),[_c('v-icon',{attrs:{"size":"64"}},[_vm._v("mdi-book-open-page-variant")]),_c('v-card-text',[_c('h2',{staticClass:"mb-2"},[_vm._v("Online Abmeldung Dienstnehmer")]),_c('p',[_vm._v("Dienstnehmerdaten für Abmeldung online erfassen")])])],1)]}}]),model:{value:(_vm.deregister_modal_fullscreen),callback:function ($$v) {_vm.deregister_modal_fullscreen=$$v},expression:"deregister_modal_fullscreen"}},[_c('Abmeldung',{on:{"close":function($event){_vm.deregister_modal_fullscreen = false;}}})],1)],1),_c('span',{staticClass:"hidden-sm-and-down"},[_c('v-dialog',{attrs:{"persistent":"","width":"1000px","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"hover",attrs:{"align":"center","max-width":"350px","flat":"","shaped":""}},'v-card',attrs,false),on),[_c('v-icon',{attrs:{"size":"64"}},[_vm._v("mdi-book-open-page-variant")]),_c('v-card-text',[_c('h2',{staticClass:"mb-2"},[_vm._v("Online Abmeldung Dienstnehmer")]),_c('p',[_vm._v("Dienstnehmerdaten für Abmeldung online erfassen")])])],1)]}}]),model:{value:(_vm.deregister_modal),callback:function ($$v) {_vm.deregister_modal=$$v},expression:"deregister_modal"}},[_c('Abmeldung',{on:{"close":function($event){_vm.deregister_modal = false;}}})],1)],1)])],1)],1),_c('v-divider',{staticClass:"mb-3"}),_c('News')],1)}
var staticRenderFns = []

export { render, staticRenderFns }