<template>
  <div>
    <v-img
        src="../../../public/accounting.jpg"
        height="35vh"
        style="text-align: center"
    >
      <v-img
          src="../../../public/personalverrechnung_tile.png"
          class="d-inline-block top_tile"
          style="position: relative; top:50%; transform: translateY(-50%);"
          height="75%"
          contain
      ></v-img>
    </v-img>

    <div class="content_tile pa-5 pb-10 mt-4">
      <div class="row">
        <div class="col-md">
          <p>Bei der derzeitigen Gesetzeslage ist die Personalverrechnung ein beinahe undurchschaubares Gebiet mit zahlreichen Fragen des Arbeits-, Steuer- und Sozialversicherungsrechts. Die Lohn- und Gehaltsverrechnung ist eine personalintensive Verpflichtung. Die Errechnung, die Durchf&uuml;hrung und die entsprechende Kontrolle sind nur eine Seite, die andere betrifft die arbeitsrechtliche Problematik. Und zu guter Letzt kommen noch die st&auml;ndigen Ver&auml;nderungen im Sozialversicherungs- und Arbeitsrecht hinzu.</p>
          <p>In Ihrem Planbudget k&ouml;nnen Sie mit einem fixen monatlichen Pauschalhonorar pro Dienstnehmer alle &uuml;blicherweise anfallenden Kosten, im Zusammenhang mit der laufenden Lohn- und Gehaltsverrechnung exakt kalkulieren.</p>
          <h3>T&auml;tigkeiten die unter das Pauschalhonorar fallen:</h3>
          <v-divider class="mb-5"></v-divider>
          <ul>
            <li>DN-Stammblatt, An-, Ab- und &auml;nderungsmeldung bei der GKK</li>
            <li>Abrechnung lfd. Lohn/Gehalt, Sonderzahlungen</li>
            <li>Zulagen, Pauschalen, Provisionen, &uuml;berstunden, &uuml;-Pauschalen und Reisekosten mit Vorgabe zur &uuml;bernahme in die Lohnverrechnung</li>
            <li>Mtl. Abgaben- und &uuml;berweisungslisten</li>
            <li>Elektronische &uuml;bermittlung der Beitragsnachweisung an die GKK</li>
            <li>Endabrechnungen, Formulare und Lohnkonten</li>
            <li>Jahreserkl&auml;rung inkl. elektronischer &uuml;bermittlung</li>
          </ul>
          <br />
          <h3>T&auml;tigkeiten die nicht unter das Pauschalhonorar fallen:</h3>
          <v-divider class="mb-5"></v-divider>
          <ul>
            <li>Reisekostenabrechnung</li>
            <li>Lohnpf&auml;ndungen</li>
            <li>Zeitabrechnungen (z.B. Zeitkarten)&nbsp;</li>
            <li>Schulung und Beratung&nbsp;</li>
            <li>Arbeits- und Sozialversicherungsrechtliche Beratung&nbsp;</li>
            <li>Arbeitnehmerveranlagung f&uuml;r Dienstnehmer&nbsp;</li>
            <li>Mithilfe bei Lohnsteuer-, Kommunalsteuer- und Sozialversicherungsbeitrags-pr&uuml;fungen</li>
          </ul>
          <br />
          <p>Natürlich haben wir auch alle Tätigkeiten in Zusammenhang mit Kurzarbeit oder allen anderen COVID bedingten Förderungen für Sie im Überblick, besonders im Hinblick auf die Personalverrechnung.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Personalverrechnung",
  mounted() {
    const slide_down = {
      distance: '5%',
      origin: 'top',
      opacity: 0,
      delay: 1,
      duration: 1500,
      easing: 'ease-in-out'
    }

    this.$scroll.reveal(".top_tile", slide_down)
  }
}
</script>

<style scoped>

</style>
