<template>
  <div>
    <div class="container-lg content_tile pa-5 pb-10">

      <p>Bei der derzeitigen Gesetzeslage ist die Personalverrechnung ein beinahe undurchschaubares Gebiet mit zahlreichen Fragen des Arbeits-, Steuer- und Sozialversicherungsrechts. Die Lohn- und Gehaltsverrechnung ist eine personalintensive Verpflichtung. Die Errechnung, die Durchf&uuml;hrung und die entsprechende Kontrolle sind nur eine Seite, die andere betrifft die arbeitsrechtliche Problematik. Und zu guter Letzt kommen noch die st&auml;ndigen Ver&auml;nderungen im Sozialversicherungs- und Arbeitsrecht hinzu.</p>
      <p>In Ihrem Planbudget k&ouml;nnen Sie mit einem fixen monatlichen Pauschalhonorar pro Dienstnehmer alle &uuml;blicherweise anfallenden Kosten, im Zusammenhang mit der laufenden Lohn- und Gehaltsverrechnung exakt kalkulieren.</p>
      <h3>T&auml;tigkeiten die unter das Pauschalhonorar fallen:</h3>
      <ul>
        <li>DN-Stammblatt, An-, Ab- und &auml;nderungsmeldung bei der GKK</li>
        <li>Abrechnung lfd. Lohn/Gehalt, Sonderzahlungen</li>
        <li>Zulagen, Pauschalen, Provisionen, &uuml;berstunden, &uuml;-Pauschalen und Reisekosten mit Vorgabe zur &uuml;bernahme in die Lohnverrechnung</li>
        <li>Mtl. Abgaben- und &uuml;berweisungslisten</li>
        <li>Elektronische &uuml;bermittlung der Beitragsnachweisung an die GKK</li>
        <li>Endabrechnungen, Formulare und Lohnkonten</li>
        <li>Jahreserkl&auml;rung inkl. elektronischer &uuml;bermittlung</li>
      </ul>
      <h3>T&auml;tigkeiten die nicht unter das Pauschalhonorar fallen:</h3>
      <ul>
        <li>Reisekostenabrechnung</li>
        <li>Lohnpf&auml;ndungen</li>
        <li>Zeitabrechnungen (z.B. Zeitkarten)&nbsp;</li>
        <li>Schulung und Beratung&nbsp;</li>
        <li>Arbeits- und Sozialversicherungsrechtliche Beratung&nbsp;</li>
        <li>Arbeitnehmerveranlagung f&uuml;r Dienstnehmer&nbsp;</li>
        <li>Mithilfe bei Lohnsteuer-, Kommunalsteuer- und Sozialversicherungsbeitrags-pr&uuml;fungen</li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {
  name: "Links"
}
</script>

<style scoped>
.content_tile {
  max-width: 1200px;
  margin: auto;
}
</style>
