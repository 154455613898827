<template>
  <div>
    <div class="content_tile pa-5 pb-10">
      <v-container>
        <v-row>
          <v-col>
            <h1>Impressum</h1>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div><h2 style="color: #297ACA;">Saischek Accounting e.U.</h2></div>
            <div><strong>Bilanzbuchhaltung und Unternehmensberatung</strong></div>
            <div>Andritzer Reichsstraße 42, 8045 Graz</div>
            <div>Tel.: 0664 / 91 47 951 </div>
            <div>E-Mail: <a href="mailto:office@saischek-accounting.at">office@saischek-accounting.at</a></div>
          </v-col>
        </v-row>
      </v-container>
      <v-container id="data">
        <v-row>
          <v-col>Gesch&auml;ftsf&uuml;hrerin</v-col>
          <v-col>Ursula Saischek</v-col>
        </v-row>
        <v-row>
          <v-col>UID-Nr</v-col>
          <v-col>ATU57308416</v-col>
        </v-row>
        <v-row>
          <v-col>FN</v-col>
          <v-col>FN5521314</v-col>
        </v-row>
        <v-row>
          <v-col>FB-Gericht</v-col>
          <v-col>LG f. ZRS Graz</v-col>
        </v-row>
        <v-row>
          <v-col>Kammerzugehörigkeit</v-col>
          <v-col>
            Mitglied der Wirtschaftskammer Österreich
          </v-col>
        </v-row>
        <v-row>
          <v-col>Aufsichtsbehörde</v-col>
          <v-col>
            Präsident der Wirtschaftskammer Österreich
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <h2>Berufsrechtliche Regelungen</h2>
        <ul>
          <li>Bundesgesetz über die Bilanzbuchhaltungsberufe (<a title="Bilanzbuchhaltungsgesetz 2014 – BiBuG 2014" href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&amp;Gesetzesnummer=20008571" target="_blank" rel="noopener">Bilanzbuchhaltungsgesetz 2014 – BiBuG 2014</a>)</li>
          <li>Verordnung des Präsidenten der Wirtschaftskammer Österreich über die Ausübung der Bilanzbuchhaltungsberufe (<a title="Bilanzbuchhaltungsberufe-Ausübungsrichtlinie 2014 – BB-AR 2014" href="https://www.wko.at/site/bilanzbuchhaltung/Verordnungen-nach-Bilanzbuchhaltungsgesetz.html" target="_blank" rel="noopener">Bilanzbuchhaltungsberufe-Ausübungsrichtlinie 2014 – BB-AR 2014</a>)</li>
          <li>Weitere Informationen auf&nbsp;<a title="www.bilanzbuchhaltung.or.at" href="http://www.bilanzbuchhaltung.or.at/" target="_blank">www.bilanzbuchhaltung.or.at</a>&nbsp;</li>
        </ul>
      </v-container>
      <v-container>
        <h2>Grundlegende Richtung</h2>
        <p>Die Webseite beinhaltet unpolitische News, die sich mit dem Steuer-,
          Sozial- und Wirtschaftsrecht beschäftigen und sich vorwiegend an Mandanten
          der Kanzlei richten.</p>
        <h2>Haftungsausschluss / § 5 ECG</h2>
        <p>Der Autor dieser Webseiten ist bestrebt, die hier angebotenen
          Informationen nach bestem Wissen und Gewissen vollständig und richtig
          darzustellen und aktuell zu halten. Dennoch kann er keinerlei Haftung für
          Schäden übernehmen, die sich aus der Nutzung der angebotenen Informationen
          ergeben können – auch wenn diese auf die Nutzung von allenfalls
          unvollständigen bzw. fehlerhaften Informationen zurückzuführen sind.</p>
        <p>Verweise auf fremde Webseiten liegen außerhalb des
          Verantwortungsbereiches des Autors. Eine Haftung für die Inhalte von
          verlinkten Seiten ist ausgeschlossen, zumal der Autor keinen Einfluss auf
          Inhalte wie Gestaltung von gelinkten Seiten hat. Für Inhalte von Seiten,
          auf welche von Seiten dieser Webseiten verwiesen wird, haftet somit allein
          der Anbieter dieser fremden Webseiten – niemals jedoch derjenige, der
          durch einen Link auf fremde Publikationen und Inhalte verweist. Sollten
          gelinkte Seiten (insbesondere durch Veränderung der Inhalte nach dem
          Setzen des Links) illegale, fehlerhafte, unvollständige, beleidigende oder
          sittenwidrige Informationen beinhalten und wird der Autor dieser Seite auf
          derartige Inhalte von gelinkten Seiten aufmerksam (gemacht), so wird er
          einen Link auf derartige Seiten unverzüglich unterbinden.</p>
        <h2>Urheberrecht</h2>
        <p>Die vom Autor selbst erstellten Inhalte (Texte und Bilder) dieser
          Seiten sind urheberrechtlich geschützt. Die Informationen sind nur für die
          persönliche Verwendung bestimmt. Jede den Bestimmungen des
          Urheberrechtsgesetzes widersprechende Verwendung jeglicher Inhalte dieser
          Webseiten – insbesondere die weitergehende Nutzung wie beispielsweise die
          Veröffentlichung, Vervielfältigung und jede Form von gewerblicher Nutzung
          sowie die Weitergabe an Dritte – auch in Teilen oder in überarbeiteter
          Form – ohne ausdrückliche Zustimmung des Autors ist untersagt.</p>
        <h2>Informationsinhalt</h2>
        <p>Die Informationen dieser Webseiten können ohne vorherige Ankündigung
          geändert, entfernt oder ergänzt werden. Der Autor kann daher keine
          Garantie für die Korrektheit, Vollständigkeit, Qualität oder Aktualität
          der bereitgestellten Informationen geben.</p>
        <h2>Rechtswirksamkeit</h2>
        <p>Durch Nutzung dieser Webseiten unterliegt der Nutzer den
          gegenständlichen Nutzungsbedingungen. Diese sind Teil des WWW-Angebotes.
          Sofern Teile oder einzelne Formulierungen der Nutzungsbedingungen der
          geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen
          sollten, bleiben die übrigen Teile der Nutzungsbedingungen in ihrem Inhalt
          und ihrer Gültigkeit davon unberührt.</p>
      </v-container>
      <v-container>
        Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU zu richten: <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>.
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
name: "Impressum"
}
</script>

<style scoped>
#data .row .col:first-child {
  font-weight: bold;
}

#data .row .col {
  margin: 2px 0;
  background-color: #dedede;
  border-radius: 2px;
}
</style>
