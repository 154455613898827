<template>
  <div>
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-dialog v-model="cancel_dialog" persistent width="unset">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                dark
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Wollen Sie wirklich abbrechen?
            </v-card-title>
            <v-card-text>Ihre eingegebenen Informationen gehen dadurch verloren</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="cancel_dialog = false"
              >
                Nein
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="cancel_dialog = false, close()"
              >
                Ja
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-toolbar-title>Online Abmeldung Dienstnehmer</v-toolbar-title>
      </v-toolbar>
      <v-stepper
        v-model="page"
        vertical
      >
        <v-stepper-step
          :complete="page > 1"
          step="1"
        >
          Firma
          <small>Angaben zur Firma</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card flat>
            <v-card-text>
              <v-form ref="company_form" v-model="company.valid" lazy-validation>
                <v-row>
                  <v-col>
                    <v-text-field v-model="company.name" :rules="rules.required">
                      <template #label>
                        Firmenname <span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="company.street" :rules="rules.required">
                      <template #label>
                        Straße <span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="company.plz" type="number" :rules="rules.required">
                      <template #label>
                        PLZ <span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="company.place" :rules="rules.required">
                      <template #label>
                        Ort <span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="company.tel" label="Telefon"></v-text-field>
                  </v-col>
                  <v-col></v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 1"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :disabled="!company.valid"
                  @click="$refs.company_form.validate() ? page = 2 : null"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
          :complete="page > 2"
          step="2"
        >
          Dienstnehmer/in
          <small>Angaben zum/zur Dienstnehmer/in</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card flat>
            <v-card-text>
              <v-form ref="employee_form" v-model="employee.valid" lazy-validation>
                <v-row>
                  <v-col>
                    <v-text-field v-model="employee.last_name" :rules="rules.required">
                      <template #label>
                        Nachname <span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                    <v-text-field v-model="employee.first_name" :rules="rules.required">
                      <template #label>
                        Vorname <span class="red--text"><strong> *</strong></span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="employee.mail">
                      <template #label>
                        Email-Adresse <span class="red--text"></span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col></v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 1"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :disabled="!employee.valid"
                  @click="$refs.employee_form.validate() ? page = 3 : null"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="page > 3"
            step="3"
        >
          Abmeldung
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card flat>
            <v-card-text>
              <v-form ref="deregister_form" lazy-validation>
                <v-row>
                  <v-col>
                    <h2>Abmeldegrund</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group v-model="deregister.reason">
                      <v-radio label="Kündigung durch den/die Arbeitsgeberin" value="Kündigung durch den/die Arbeitsgeberin"></v-radio>
                      <v-radio label="Kündigung durch den/die Dienstnehmer/in" value="Kündigung durch den/die Dienstnehmer/in"></v-radio>
                      <v-radio label="Einvernehmliche Lösung" value="Einvernehmliche Lösung"></v-radio>
                      <v-radio label="Zeitablauf" value="Zeitablauf"></v-radio>
                      <v-radio label="Unberechtiger, vorzeitiger Austritt" value="Unberechtiger, vorzeitiger Austritt"></v-radio>
                      <v-radio label="Fristlose Entlassung" value="Fristlose Entlassung"></v-radio>
                      <v-radio label="Anderer Grund" value="Anderer Grund"></v-radio>
                    </v-radio-group>
                    <v-text-field label="Anderer Grund" v-show="deregister.reason === 'Anderer Grund'" v-model="deregister.reason_text"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="deregister.open_vacation" label="Offener Urlaub" suffix="Tage" type="number" style="max-width:200px;"></v-text-field>
                  </v-col>
                  <v-col></v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h2>Offene Gutstunden</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="deregister.goodhours" label="Anzahl" suffix="Tage" type="number" style="max-width:200px;" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-radio-group v-model="deregister.open_goodhours">
                      <v-radio label="50%" value="50%"></v-radio>
                      <v-radio label="100%" value="100%"></v-radio>
                      <v-radio label="1:1" value="1:1"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 2"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="$refs.deregister_form.validate() ? page = 4 : null"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="page > 4"
            step="4"
        >
          Sonstiges
        </v-stepper-step>

        <v-stepper-content step="4">
          <v-card flat>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-col>
                    <v-textarea v-model="other.notes" label="Anmerkungen" outlined>
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h2>Dokumente</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-file-input
                        v-model="other.files"
                        color="primary"
                        multiple
                        placeholder="Klicken um Dateien hinzuzufügen"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                            v-if="index < 4"
                            color="primary accent-4"
                            dark
                            label
                            small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                            v-else-if="index === 4"
                            class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} Dokument(e)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="page = 3"
              >
                Zur&uuml;ck
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="page = 5"
              >
                Weiter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step
            :complete="page > 5"
            step="5"
        >
          Versand
        </v-stepper-step>

        <v-stepper-content step="5">
          <v-form ref="dispatch_form" v-model="dispatch.valid" lazy-validation>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>

                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="dispatch.copy" @click="$refs.mail.validate()" true-value="Ja" false-value="Nein" label="Eine Kopie an meine E-Mail-Adresse senden"></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="dispatch.mail" label="Ihre E-Mail-Adresse" ref="mail" :disabled="dispatch.copy === 'Nein'" :rules="[ mailRequired ]" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="dispatch.agreement" :rules="rules.required">
                      <template #label>
                        <p><span class="red--text"><strong> *</strong></span> Ich versichere, die oben gemachten Angaben vollständig und wahrheitsgemäß gemacht zu haben.
                          Sollten sich im Verlauf meiner Beschäftigung Veränderungen ergeben, werde ich diese unverzüglich
                          mitteilen. Soweit meinem Arbeitgeber durch unvollständige oder unrichtige Angaben Nachteile
                          entstehen, bin ich schadenersatzpflichtig</p>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    @click="page = 4"
                >
                  Zur&uuml;ck
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    :disabled="!dispatch.valid"
                    @click="$refs.dispatch_form.validate() ? send() : null"
                >
                  Absenden
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </div>
</template>

<script>
import {serialize} from "object-to-formdata";

export default {
  name: "Abmeldung",
  data: () => ({
    page: 1,
    rules: {
      required: [v => !!v || "Erforderlich"],
    },
    // 1. Company form
    company: {
      valid: true,
      name: null,
      street: null,
      plz: null,
      place: null,
      tel: null
    },
    // 2. Emplyee form
    employee: {
      valid: true,
      last_name: null,
      first_name: null,
      mail: null,
    },
    // 3. Deregister form
    deregister: {
      end_of_employment: null,
      reason: null,
      reason_text: null,
      open_vacation: null,
      open_goodhours: null,
      goodhours: null,
    },
    // 4. Other form
    other: {
      notes: null,
      files: []
    },
    // 5. Dispatch form
    dispatch: {
      valid: true,
      copy: null,
      mail: null,
      agreement: null
    },
    cancel_dialog: false,
    options: {
      /**
       * include array indices in FormData keys
       * defaults to false
       */
      indices: false,

      /**
       * treat null values like undefined values and ignore them
       * defaults to false
       */
      nullsAsUndefineds: false,

      /**
       * convert true or false to 1 or 0 respectively
       * defaults to false
       */
      booleansAsIntegers: false,

      /**
       * store arrays even if they're empty
       * defaults to false
       */
      allowEmptyArrays: false,

      /**
       * don't include array notation in FormData keys for Files in arrays
       * defaults to false
       */
      noFilesWithArrayNotation: false,

      /**
       * use dots instead of brackets for object notation in FormData keys
       * defaults to false
       */
      dotsForObjectNotation: true,
    },
  }),
  methods: {
    close() {
      this.$emit("close");
    },

    mailRequired(val) {
      if (!val && this.dispatch.copy === "Ja") {
        return "Required";
      } else {
        return true;
      }
    },

    send() {
      this.loading = true;

      const data = {
        company: this.company,
        employee: this.employee,
        deregister: this.deregister,
        other: this.other,
        dispatch: this.dispatch
      };

      let fd = new FormData();

      let it;
      for (it = 0; it < this.other.files.length; it++) {
        fd.append("userfile_" + it, this.other.files[it]);
      }

      fd.append("total_files", this.other.files.length);
      fd.append("event", "deregistration");

      fd = serialize(data, this.options, fd);

      this.axios
          // .post("/mail.php", fd)
          .post("http://www.saischek-accounting.at/mail.php", fd)
          .then(res => {
            if (res.data == true) {
              this.$notify({
                group: 'default',
                title: 'Erfolgreich',
                type: 'success',
                text: 'Ihre Dienstnehmer Abmeldung wurde erfolgreich versand!'
              });
              this.$emit("close");
            } else {
              this.$notify({
                group: 'default',
                title: 'Fehler',
                type: 'error',
                text: 'Ein Fehler ist aufgetreten! Bitte überprüfen Sie ihre eingegebenen Daten!'
              });
              console.error(res.data);
            }
          })
          .catch(err => {
            this.$notify({
              group: 'default',
              title: 'Fehler',
              type: 'error',
              text: 'Ein Fehler ist aufgetreten! Bitte versuchen Sie es erneut!'
            });
            console.error(err);
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
