<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card class="card" max-width="600" min-width="380" width="unset" height="unset" ripple @click="openDialog(item)">
          <v-img v-if="item.image" :src="item.image" height="220" class="ma-1" cover></v-img>
          <v-card-title style="word-break: break-word !important;">{{ item.title }}</v-card-title>
          <v-card-subtitle class="subtitle-2" style="font-size: 0.7em !important;">{{ item.timestamp }}</v-card-subtitle>
          <v-card-text v-html="item.text.length > 300 ? item.text.substring(0, 300) + ' ...' : item.text">
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="950"
        v-model="dialog.show"
    >
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >
          <span class="ml-3" style="font-size: 18px;">{{ item.title }}</span>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                v-if="admin"
                icon
                @click="edit"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
                v-if="admin"
                icon
                @click="delete_dialog = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
                icon
                @click="dialog.show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-img v-if="item.image" :src="item.image" class="mt-3 mb-4" max-height="300px" contain></v-img>
          <div class="mt-3 text-body-1" v-html="item.text"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-subtitle class="subtitle-2 py-2 px-3">{{ item.timestamp}}</v-card-subtitle>
      </v-card>
    </v-dialog>

    <v-dialog v-model="delete_dialog" persistent width="unset">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            dark
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Sind Sie sich sicher?
        </v-card-title>
        <v-card-text>Wollen Sie wirklich diesen Eintrag löschen?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="delete_dialog = false"
          >
            Nein
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="delete_dialog = false, del()"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    item: {
      type: Object,
      default: {}
    },
    admin: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loading: true,
    delete_dialog: false,
    dialog: {
      show: false
    }
  }),

  methods: {
    openDialog() {
      this.dialog.show = true;
    },

    edit() {
      this.$emit("edit", this.item)
      this.dialog.show = false;
    },

    del() {
      this.axios.delete('db.php', { data: this.item.id })
          .then(res => {
            if (res.data === "success") {
              this.$notify({
                group: 'default',
                title: 'Erfolgreich',
                type: 'success',
                text: 'Der Eintrag wurde erfolgreich gelöscht!'
              });

              this.dialog.show = false;

              this.$emit("delete", this.item.id);
            }
          })
          .catch(err => {
            this.$notify({
              group: 'default',
              title: 'Fehler',
              type: 'error',
              text: 'Ein Fehler ist aufgetreten!'
            });
            console.log(err);
          })
          .finally(() => {
          });
    }
  }
}
</script>

<style scoped>

</style>
